<template>
  <v-select
    @input="changeData($event)"
    @search="searchAdress"
    placeholder="Название улицы"
    :options="searchResult"
    appendToBody
    :disabled="disabled"
    class="select-size-sm"
    label="title"
    :value="value"
    :filter="fuseSearch"
    :clearSearchOnBlur="
      ({ clearSearchOnSelect }) => {
        return !clearSearchOnSelect;
      }
    "
  >
    <!-- <template slot="option" slot-scope="option">
      <div v-if="option.type === 'fast_address'">
        <div style="color: #343434">
          {{ option.name }}
        </div>
        <div v-if="option.street_type == 'микрорайон'" style="font-size: 12px">
          {{
            option.village +
            ", " +
            option.region +
            ", " +
            (option.street ? option.street : "") +
            " " +
            (option.street_type ? option.street_type : " ") +
            " " +
            (option.addr_relation ? "," + option.addr_relation : "")
          }}
        </div>
        <div v-else style="font-size: 12px">
          {{
            option.village +
            ", " +
            option.region +
            ", " +
            (option.street_type ? option.street_type : " ") +
            " " +
            (option.street ? option.street : "") +
            " " +
            (option.addr_relation ? "," + option.addr_relation : "")
          }}
        </div>
      </div>
      <div v-if="option.type === 'address'">
        <div v-if="option.street_type == 'микрорайон'">
          <div style="color: #343434; font-size: 14px">
            {{ option.street + " " + option.street_type + ", " + option.name }}
          </div>
          <div style="font-size: 12px">
            {{ option.village + ", " + option.region }}
          </div>
        </div>
        <div v-else>
          <div style="color: #343434; font-size: 14px">
            {{ option.street_type + " " + option.street + ", " + option.name }}
          </div>
          <div style="font-size: 12px">
            {{ option.village + ", " + option.region }}
          </div>
        </div>
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div v-if="option.type === 'fast_address'">
        {{ option.name }}
      </div>
      <div v-if="option.type === 'address'">
        <div v-if="option.street_type == 'микрорайон'">
          <div>
            {{ option.street + " " + option.street_type + ", " + option.name }}
          </div>
        </div>
        <div v-else>
          <div>
            {{ option.street_type + " " + option.street + ", " + option.name }}
          </div>
        </div>
      </div>
    </template> -->
    <template #no-options="{ search } = data">
      <span v-if="search"> Такого адреса нет в БД! </span>
      <span v-else> Введите адрес </span>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import Fuse from "fuse.js";
export default {
  components: {
    vSelect,
  },
  props: ["value", "disabled", "defaultAddresses"],
  data() {
    return {
      addresses: [],
      searchTimeout: null,
      searchQuery: "",
    };
  },
  methods: {
    changeData(val) {
      this.$emit("input", val);
    },
    searchAdress(searchText, loading) {
      this.searchQuery = searchText;
      if (!searchText) return;
      clearInterval(this.searchTimeout);
      loading(true);
      this.searchTimeout = setTimeout(() => {
        this.$http
          .post(`directory/search-addresses`, { search: searchText })
          .then((res) => {
            this.addresses = res.data;
            loading(false);
          })
          .catch((err) => {
            loading(false);
          });
      }, 100);
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name", "synonyms", "search"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
  },
  computed: {
    searchResult() {
      let result = this.addresses;
      if (
        this.value &&
        this.value.id &&
        !this.addresses.find((el) => this.value.id === el.id) &&
        this.defaultAddresses &&
        !this.defaultAddresses.find((el) => this.value.id === el.id)
      ) {
        result.push(this.value);
      }
      if (result.length <= 1) {
        result = result.concat(this.defaultAddresses);
      }
      return result;
    },
  },
};
</script>
