var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"select-size-sm",attrs:{"placeholder":"Название улицы","options":_vm.searchResult,"appendToBody":"","disabled":_vm.disabled,"label":"title","value":_vm.value,"filter":_vm.fuseSearch,"clearSearchOnBlur":function (ref) {
      var clearSearchOnSelect = ref.clearSearchOnSelect;

      return !clearSearchOnSelect;
    }},on:{"input":function($event){return _vm.changeData($event)},"search":_vm.searchAdress},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
    if ( ref === void 0 ) ref = _vm.data;
    var search = ref.search;
return [(search)?_c('span',[_vm._v(" Такого адреса нет в БД! ")]):_c('span',[_vm._v(" Введите адрес ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }